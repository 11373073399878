//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
//import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";
import Popup from "../../../components/Popup.vue";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

//const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Popup,
},
  data() {
    return {
      submitted: false,

      selectedService: 0,
      servicesList: [],
      templatesList: [],

      // Новый шаблон
      addTemplatePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editTemplatePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      newTemplate: {
        id: 0,
        category_name: '',
        name: '',
      },
      newTemplateFiles: [],
      newTemplateParameters: [],
      existsTemplateFiles: [],

      deleteTemplatePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletedTemplateId: 0,

      title: this.$t('menuitems.services.list.templates'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.services.list.templates'),
          active: true
        }
      ],
    };
  },
  validations: {
    newTemplate: {
      category_name: { required, minLength: minLength(1), maxLength: maxLength(80) },
      name: { required, minLength: minLength(1), maxLength: maxLength(150) }
    }
  },
  watch: {
    selectedService: function(){
      this.getTemplates();
    }
  },
  computed: {
    
  },
  methods: {
    closeDeleteTemplatePopup(){
      this.deleteTemplatePopup.show = false;
    },
    showDeleteTemplatePopup(id){
      this.deleteTemplatePopup.show = true;
      this.deletedTemplateId = id;
    },
    deleteTemplatePopupSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedTemplateId);
      axios
          .post(`/v1/services-list/delete-template`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.submitted = false;
              this.closeDeleteTemplatePopup();
              this.getTemplates();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    removeEditTemplateParameter(idx){
      this.newTemplateParameters.splice(idx, 1);
    },
    removeExistsImg(idx){
      this.existsTemplateFiles.splice(idx, 1);
    },
    showEditTemplatePopup(templateId){
      this.editTemplatePopup.show = true;
      for(let key in this.templatesList){
        if(+this.templatesList[key].id === +templateId){
          this.$set(this.newTemplate, 'id', this.templatesList[key].id);
          this.$set(this.newTemplate, 'category_name', this.templatesList[key].category_name);
          this.$set(this.newTemplate, 'name', this.templatesList[key].name);

          if(this.templatesList[key].parameters_decode.length){
            this.newTemplateParameters = this.templatesList[key].parameters_decode;
          }
          if(this.templatesList[key].servicesTemplatesFiles.length){
            this.existsTemplateFiles = this.templatesList[key].servicesTemplatesFiles;
          }
        }
      }
    },
    closeEditTemplatePopup(){
      this.editTemplatePopup.show = false;
      this.resetData();
    },
    editTemplatePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newTemplate.$touch();
      if(!this.$v.newTemplate.$invalid){
        let formData = new FormData();

        formData.append('service_id', this.selectedService);

        for(let key in this.newTemplate){
          formData.append(key, this.newTemplate[key]);
        }
        if(this.newTemplateFiles.length){
          let z = 0;
          for(let key in this.newTemplateFiles){
            formData.append(
              'files[' +z +']['+ this.newTemplateFiles[key].template_codename +']',
              this.newTemplateFiles[key].file[0]
            );
            formData.append(
              'filesList[' +z +']',
              this.newTemplateFiles[key].template_codename
            );
            z++;
          }
        }
        if(this.existsTemplateFiles.length){
          let z = 0;
          for(let key in this.existsTemplateFiles){
            formData.append(
              'existsFiles[' +z +'][id]',
              this.existsTemplateFiles[key].id
            );
            z++;
          }
        }
        if(this.newTemplateParameters.length){
          for(let key in this.newTemplateParameters){
            formData.append(
              'parameters[' +key +'][key]', 
              this.newTemplateParameters[key].key
            );
            formData.append(
              'parameters[' +key +'][type]', 
              this.newTemplateParameters[key].type
            );
            formData.append(
              'parameters[' +key +'][value]', 
              this.newTemplateParameters[key].value
            );
          }
        }
        axios
          .post(`/v1/services-list/update-template`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.submitted = false;
              this.closeEditTemplatePopup();
              this.getTemplates();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
      }
    },
    addParam(){
      let row = {key: '', type: '', value: ''};
      // this.newTemplateParameters.push(row);
      this.$set(this.newTemplateParameters, this.newTemplateParameters.length, row);
    },
    selectFiles(idx, event) {
      this.newTemplateFiles[idx].file = event.target.files;
      //this.banner.file = event.target.files;
    },
    pushTemplateFile(){
      let row = {template_codename: '', file: []};
      this.$set(this.newTemplateFiles, this.newTemplateFiles.length, row);
    },
    addTemplatePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newTemplate.$touch();
      if(!this.$v.newTemplate.$invalid){
        let formData = new FormData();

        formData.append('service_id', this.selectedService);

        for(let key in this.newTemplate){
          formData.append(key, this.newTemplate[key]);
        }
        if(this.newTemplateFiles.length){
          let z = 0;
          for(let key in this.newTemplateFiles){
            formData.append(
              'files[' +z +']['+ this.newTemplateFiles[key].template_codename +']',
              this.newTemplateFiles[key].file[0]
            );
            formData.append(
              'filesList[' +z +']',
              this.newTemplateFiles[key].template_codename
            );
            z++;
          }
        }
        if(this.newTemplateParameters.length){
          for(let key in this.newTemplateParameters){
            formData.append(
              'parameters[' +key +'][key]', 
              this.newTemplateParameters[key].key
            );
            formData.append(
              'parameters[' +key +'][type]', 
              this.newTemplateParameters[key].type
            );
            formData.append(
              'parameters[' +key +'][value]', 
              this.newTemplateParameters[key].value
            );
          }
        }
        axios
          .post(`/v1/services-list/create-template`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.submitted = false;
              this.closeAddTemplatePopup();
              this.getTemplates();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
      }
    },
    resetData(){
      this.newTemplate = {
        id: 0,
        category_name: '',
        name: ''
      };
      this.newTemplateFiles = [];
      this.newTemplateParameters = [];
      this.existsTemplateFiles = [];
    },
    closeAddTemplatePopup(){
      this.addTemplatePopup.show = false;
      this.resetData();
    },
    showAddTemplatePopup(){
      if(this.selectedService === 0){
        this.$store.dispatch("addNotification", {
          text: this.$t('views.templates.select_a_service_first'),
          time: 3,
          color: "danger"
        });
        return false;
      }
      this.addTemplatePopup.show = true;
    },
    getTemplates(){
      if(this.selectedService > 0){
        let formData = new FormData();
        formData.append('service_id', this.selectedService);

        axios
          .post(`/v1/services-list/get-templates`, formData)
          .then(resp => {
            console.log(resp.data);
            this.templatesList = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
      }
    },
    getActiveServices(){
      axios
          .get(`/v1/services-list/get-all`)
          .then(resp => {
            this.servicesList = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getActiveServices();
  }
};
